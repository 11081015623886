import {
  defaultError,
  errorCodesConstants,
  invalidValueError,
} from '../constants/errorConstants';
import { logger } from '../services/logger';
import mixpanel from 'mixpanel-browser';
import { INVALID_EDIT_EVENT } from '../constants/constants.js';

export const throwInvalidValue = (value, field) => {
  mixpanel.track(INVALID_EDIT_EVENT, { value: value, field: field });
  let customError = new Error('Invalid value');
  customError.code = value;
  throw customError;
};

export const parseError = (error) => {
  logger.catchError(error);

  if (error.response) {
    const statusCategory = errorCodesConstants[error.response.status];

    if (statusCategory) {
      const errorDefinition = statusCategory[error.response.data.error_code];

      if (errorDefinition) {
        if (typeof errorDefinition === 'function') {
          return errorDefinition({
            errorParams: error.response.data.error_params,
          });
        } else {
          return errorDefinition;
        }
      }

      if (statusCategory.default) {
        return statusCategory.default;
      }
    }
    return defaultError;
  } else {
    if (error.code === 'ERR_NETWORK') {
      return errorCodesConstants[0].default;
    } else if (error.message === 'Invalid value' && error.code) {
      return invalidValueError(error.code);
    }

    return defaultError;
  }
};
