import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';
import App from './App';
import { setupStore } from './app/store';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ErrorProvider } from './ErrorProvider';
import mixpanel from 'mixpanel-browser';

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
const API_URL = import.meta.env.VITE_SERVER_API_URL;
const ENV = import.meta.env.VITE_NODE_ENV;
const SESSION_RATE = import.meta.env.VITE_REPLAY_SESSION_SAMPLE_RATE;

let mappingsRequestRegex = new RegExp(
  `^${API_URL.replace(/\./g, '\\.')}/mappings/[^/]+$`
);

// Near entry of your product, init Mixpanel
mixpanel.init(MIXPANEL_TOKEN, {
  debug: ENV === 'development',
  track_pageview: true,
  persistence: 'localStorage',
  ip: 'false',
});

if (ENV !== 'playground') mixpanel.disable();

Sentry.init({
  dsn: SENTRY_DSN,
  // should be set to wanted value on production and 0 on other environments (for other envs error session is enough)
  replaysSessionSampleRate: parseFloat(SESSION_RATE) || 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:

      beforeAddRecordingEvent: (event) => {
        // Do not capture GET requests body for mappings/{job_id} endpoint
        if (
          event.data.tag === 'performanceSpan' &&
          mappingsRequestRegex.test(event.data.payload.description) &&
          event.data.payload.data.method === 'GET'
        ) {
          delete event.data.payload.data.response.body;
          return event;
        }
        return event;
      },
      networkDetailAllowUrls: [
        `${API_URL}/admin/mapping`,
        `${API_URL}/admin/add_code_tags`,
        `${API_URL}/admin/remove_code_tags`,
        `${API_URL}/admin/replace_code_tags`,
        `${API_URL}/mapping_jobs/`,
        `${API_URL}/verification_pipeline_statuses`,
        `${API_URL}/groups`,
        `${API_URL}/revenue_categories`,
        `${API_URL}/code_tags`,
        new RegExp(
          `^${API_URL.replace(/\./g, '\\.')}/mappings/.+/replace_code_tags`
        ),
        new RegExp(
          `^${API_URL.replace(/\./g, '\\.')}/mappings/.+/add_code_tags`
        ),
        new RegExp(
          `^${API_URL.replace(/\./g, '\\.')}/mappings/.+/remove_code_tags`
        ),
        //mappings/{job_id}
        new RegExp(`^${API_URL.replace(/\./g, '\\.')}/mappings/[^/]+$`),
      ],
      networkCaptureBodies: true,
      maskAllText: false,
      networkRequestHeaders: ['Cache-Control'],
      networkResponseHeaders: ['Referrer-Policy'],
      minReplayDuration: 15000,
    }),
  ],
  // traceSampleRate is a number between 0 and 1 (0 meaning 0% and 1.0 100%)
  // Once we start using the app in production this number will have to be reconfigured.
  // Best would be to use tracesSampler function to set rates based on type of error, frequency, ect..
  tracesSampleRate: 1.0,
  environment: ENV,
  enabled: ENV !== 'development',
});

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider store={setupStore({})}>
      <ErrorProvider>
        <App />
      </ErrorProvider>
    </Provider>
  </StrictMode>
);
